<template>
  <div class="basic-info">
    <div class="basic-list">
      <van-loading v-if="datas.basicList.length === 0" size="24px" vertical>加载中...</van-loading>
      <DictItem v-for="item in datas.basicList" :key="item.id" :read-only="readOnly" :infos="item" />
    </div>
    <div v-if="!readOnly" class="bottom">
      <van-button
        round
        block
        type="info"
        color="linear-gradient(90deg, #FE9D02 0%, #F78017 100%)"
        style="margin-bottom: 20px;"
        @click="goNext"
      >
        下一步
      </van-button>
    </div>
  </div>
</template>

<script>
import { getDictUserInfoByType } from '@/api/product'
import { selectUserDictByType } from '@/api/user'
import DictItem from '@/components/DictItem'
export default {
  components: { DictItem },
  props: {
    datas: {
      type: Object,
      default: () => {}
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLock: false
    }
  },
  mounted() {
    if (this.datas.basicList.length === 0) {
      this.getDictUserInfoByType()
    }
  },
  methods: {
    async getDictUserInfoByType() {
      const res = this.$route.query.orderId || this.$route.query.lastOrderId ? await selectUserDictByType({ type: 1 }) : await getDictUserInfoByType({ type: 1 })
      this.datas.basicList = res.datas
    },
    goNext() {
      this.$emit('changeStatus', 3)
    }
  }
}
</script>

<style lang="scss" scoped>
.basic-info {
  height: 100%;
  overflow-y: auto;
  padding: 0 16px;
  box-sizing: border-box;
  .basic-list {
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 8px 0px rgba(162,162,162,0.1);
    background: #fff;
  }
  .bottom {
    padding: 30px 16px 30px 16px;
  }
}
</style>
