<template>
  <div class="order-change">
    <div class="step">
      <div class="flex text-center">
        <div v-for="(item, index) in stepList" :key="item.id" class="cu-item" :class="(index + 1)==active?'cur':''" @click="changeActive(index+1)">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="content">
      <LoanInfo v-if="active==1" :datas="form" :read-only="readOnly" @changeStatus="changeActive" />
      <BasicInfo v-if="active==2" :datas="form" :read-only="readOnly" @changeStatus="changeActive" />
      <AssetInfo v-if="active==3" :datas="form" :read-only="readOnly" @changeStatus="changeActive" />
      <CreditInfo v-if="active==4" :datas="form" :read-only="readOnly" @onSubmit="onSubmit" />
    </div>
  </div>
</template>

<script>
import LoanInfo from './components/LoanInfo'
import BasicInfo from './components/BasicInfo'
import AssetInfo from './components/AssetInfo'
import CreditInfo from './components/CreditInfo'
import { addUserInfoAll } from '@/api/user'
export default {
  components: { LoanInfo, BasicInfo, AssetInfo, CreditInfo },
  data() {
    return {
      active: 1,
      step: 1,
      stepList: [
        { id: 1, title: '贷款信息' },
        { id: 2, title: '基本信息' },
        { id: 3, title: '资产信息' },
        { id: 4, title: '征信信息' }
      ],
      form: {
        cityCode: '',
        loanTypeCode: '',
        loansAreUsedFor: '',
        unit: '万',
        fromType: '3',
        money: '',
        nickname: '',
        basicList: [],
        assetList: [],
        creditList: [],
        salesId: '',
        orderId: null //  生存orderId
      },
      isLock: false,
      readOnly: false // 设置贷款信息是否只读
    }
  },
  created() {
    const { orderId, salesmanId, readOnly } = this.$route.query
    if (orderId) {
      this.form.orderId = orderId
    }
    if (salesmanId) {
      this.form.salesId = salesmanId
    }
    this.readOnly = !!readOnly
  },
  methods: {
    changeActive(val) {
      if (this.readOnly) {
        // 假如是只读不进入非空判断
        this.active = val
        this.step = val
        document.title = this.stepList[this.active - 1].title
        return
      }
      if (val === 2) {
        if (!this.form.nickname) {
          return this.$toast('请输入姓名！')
        }
        if (this.form.money < 1 || this.form.money > 10000) {
          return this.$toast('贷款金额需在1-10000之间！')
        }
        if (!this.form.loanTypeCode) {
          return this.$toast('请选择贷款类型！')
        }
        if (!this.form.loansAreUsedFor) {
          return this.$toast('请输入贷款用途！')
        }
      }
      if (val === 3) {
        this.form.basicList.map(item => {
          if (this.isLock) return
          this.isSelected(item)
        })
      }
      if (val === 4) {
        this.form.assetList.map(item => {
          if (this.isLock) return
          this.isSelected(item)
        })
      }
      if (this.isLock) return
      this.active = val
      this.step = val
      document.title = this.stepList[this.active - 1].title
      console.log(this.form, '22')
    },
    isSelected(item) {
      if (item.children.length === 0) return // 是否有选项，没用则返回
      // 查找是否有选中项
      const selectItem = item.children.filter(it => {
        return it.selected
      })
      // 假如没用选中项则加锁
      if (selectItem.length === 0) {
        this.isLock = true
      }
      // 根据加锁状态处理对应事件
      if (this.isLock) {
        const options = {
          message: '请选择' + item.name,
          onClose: () => {
            this.isLock = false
          }

        }
        return this.$toast(options)
      } else {
        // 判断是否有下级，有的话将下级传递到函数中再次判断是否选中
        if (selectItem[0].children.length > 0) {
          return this.isSelected(selectItem[0].children[0])
        }
      }
    },
    async onSubmit() {
      this.form.creditList.map(item => {
        if (this.isLock) return
        this.isSelected(item)
      })
      if (this.isLock) return
      const sumbitLoad = this.$toast.loading({
        message: '',
        forbidClick: true,
        duration: 0
      })
      const basicList = this.form.basicList
      const assetList = this.form.assetList
      const creditList = this.form.creditList
      const listUserInfoAddByDictReq = [
        {
          sysDict: basicList,
          type: 1
        },
        {
          sysDict: creditList,
          type: 2
        },
        {
          sysDict: assetList,
          type: 3
        }
      ]
      delete this.form.basicList
      delete this.form.assetList
      delete this.form.creditList
      await addUserInfoAll({
        ...this.form,
        listUserInfoAddByDictReq
      })
      sumbitLoad.clear()
      this.$store.dispatch('user/setSelectMsgStatus', true)
      this.$router.go(-1)
    }
  }

}
</script>

<style lang="scss" scoped>
.order-change {
  display: flex;
  flex-flow: column;
  height: 100%;
  background: #fcfcfc;
  .content {
    padding-top: 60px;
    flex: 1;
    overflow: hidden;
    position: relative;
    z-index: 10;
  }
}
.step {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  .flex {
    display: flex;
  }
  .text-center {
    text-align: center;
  }
  .cu-item {
    flex: 1;
    padding: 14px 0;
    font-size: 14px;
    color: #666666;
    &.cur {
      position: relative;
      color: #ff7700;
      &::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -14px;
        width: 28px;
        height: 3px;
        border-radius: 3px;
        background: #FF7226;
      }
    }
  }
}
</style>
